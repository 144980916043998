import React from 'react'
import { useState, useEffect } from '@wordpress/element'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'

import elEqualHeight from './dom-handler'

const HeroSlider = ({ slides }) => {
  const [flkty, setFlkty] = useState(undefined)

  useEffect(() => {
    if (flkty != undefined) {
      elEqualHeight('.matchHeight')
      setTimeout(() => flkty.resize(), 300)
    }
  }, [flkty])

  let flickityOptions = {
    pageDots: false,
    prevNextButtons: true,
    adaptiveHeight: false,
    wrapAround: true,
  }

  return (
    <Flickity
      className={'flickity-styles hero-slider'}
      elementType={'div'}
      options={flickityOptions}
      disableImagesLoaded={false}
      reloadOnUpdate
      static
      flickityRef={setFlkty}
    >
      {slides.map((post, i) => (
        <div
          key={i}
          className="header-slider__item"
          dangerouslySetInnerHTML={{ __html: post }}
        />
      ))}
    </Flickity>
  )
}

HeroSlider.propTypes = {
  slides: PropTypes.array.isRequired,
  isPrev: PropTypes.bool, // check if in admin
}

HeroSlider.defaultProps = {
  isPrev: false,
}

export default HeroSlider
